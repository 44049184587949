<template>
  <div class="p-10 pr-16 all">
    <success v-if="activeSuccess" :pdf="pdf" :multiplePdf="multiplePdf" :message="message" :activation="activeSuccess" @oga="retourSuccess"/>
    <recap v-if="activeRecap" :activation="activeRecap" :cheque="cheques" :billet="billeterie" @info="retourPdf" @oga="retourRecap"/>
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-4/5 flex text-left text-c36 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retour"
          />
          <div class="ml-4 w-3/5">
            Transferts de fonds
          </div>
        </div>

        <div class="w-1/5">
          <bouton v-if="position < 3" label="Suivant" height="54.4px" @info="continuer"/>
          <bouton v-if="position === 3" label="Enregistrer" height="54.4px" @info="continuer" />
        </div>
      </div>

      <div class="mt-10 pl-4">
        <div class="flex mt-6 justify-center bg-white rounded-10 p-6 pl-10 pr-10">
          <div class="w-full" @click="selected(1)">
            <steep-line :all-steep="allSteep" :position="position" :index="0"/>
          </div>
          <div class="w-auto " @click="selected(2)">
            <steep-line :all-steep="allSteep" :position="position" :index="1"/>
          </div>
<!--          <div class="w-1/5" @click="selected(3)">-->
<!--            <steep-line :all-steep="allSteep" :position="position" :index="2"/>-->
<!--          </div>-->
        </div>
      </div>

      <div class="mt-10 pl-4">
        <espece v-if="position === 1" @info="retourBilleterie"/>
        <cheque v-if="position === 2" @info="retourCheque"/>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import steepLine from '../../component/helper/add/steepLine'
import espece from '../../component/cdevs/transfere/espece'
import cheque from '../../component/cdevs/transfere/cheque'
import recap from '../../component/popup/recapTransfere'
import success from '../../component/popup/success'

export default {
  name: "Index",

  components: {
    bouton,
    steepLine,
    espece,
    cheque,
    recap,
    success
  },

  data () {
    return {
      icons: {
        back,
      },
      allSteep: ['Espèce', 'Chèque'],
      position: 1,
      activeRecap: false,
      activeSuccess: false,
      message: 'Transfert éffectué avec succes',
      billeterie: null,
      cheques: null,
      pdf: null,
      multiplePdf: null
    }
  },

  created () {
  },

  methods: {
    selected (index) {
      this.position = index
    },

    retourBilleterie (answer) {
      this.billeterie = answer
    },

    retourCheque (answer) {
      this.cheques = answer
    },

    continuer () {
      if (this.position < 2) {
        this.position = this.position + 1
      } else {
        this.activeRecap = true
      }
    },

    retourRecap (answer) {
      if (answer !== false) {
        this.pdf = answer
        this.activeSuccess = true
      }
      this.activeRecap = false
    },

    retourPdf (answer) {
      this.multiplePdf = answer
      this.activeSuccess = true
      this.activeRecap = false
    },

    retour () {
      window.history.back()
    },

    retourSuccess() {
      this.$router.push('/caisses')
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: transparent;
}
.all{
  min-height: 100vh;
}

</style>
